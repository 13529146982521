import cx from "classnames";
import React, { FC } from "react";
import { FaEnvelope, FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { NewsletterForm } from "../../blocks/newsletterForm/newsletterForm";

import { NavLink } from "../../helpers/NavLink";
import { Logo } from "../../helpers/Svg";
import { sendGA } from "../../helpers/tracking";

import global from "../../scss/global.module.scss";
import styles from "./footer.module.scss";

interface SocialIcon {
  item: {
    label: React.ReactNode;
    url: string;
  };
}

const SocialIcon: FC<SocialIcon> = (props: SocialIcon) => {
  const { item } = props;
  let Icon = item.label;
  let iconStyle = {};
  switch (item.label) {
    case "E-mail":
      Icon = <FaEnvelope />;
      iconStyle = {
        color: "#262b7d",
      };
      break;
    case "Facebook":
      Icon = <FaFacebookF />;
      iconStyle = {
        color: "#4267B2",
      };
      break;
    case "LinkedIn":
      Icon = <FaLinkedinIn />;
      iconStyle = {
        color: "#2867B2",
      };
      break;
    case "Twitter":
      Icon = <FaTwitter />;
      iconStyle = {
        color: "#00acee",
      };
      break;
    default:
      break;
  }
  return (
    <div>
      <a
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.rsLink}
        style={{ ...iconStyle }}
        onClick={() =>
          sendGA("click", {
            event_label: `footer - rs - ${item.label}`,
          })
        }
      >
        {Icon}
      </a>
    </div>
  );
};

interface Rs {
  label: string;
  url: string;
}

interface BlogNav {
  title: string;
  mainUrl: string;
  links: {
    text: string;
    url: string;
  }[];
}

interface SiteNav {
  text: string;
  url: string;
  externe?: boolean;
}

const Footer = () => {
  const rs: Rs[] = [
    {
      label: "Facebook",
      url: "https://www.facebook.com/TeamElinoi/",
    },
    {
      label: "LinkedIn",
      url: "https://fr.linkedin.com/company/elinoi",
    },
  ];

  const blogNav: BlogNav = {
    title: "Le blog elinoï :",
    mainUrl: "https://blog.elinoi.com",
    links: [
      {
        text: "Nos témoignages",
        url: "/category/temoignages",
      },
      {
        text: "Nos conseils",
        url: "/category/conseils",
      },
      {
        text: "Les métiers en startups",
        url: "/category/emplois-en-startups",
      },
    ],
  };

  const siteNav: SiteNav[] = [
    {
      text: "FAQ",
      url: "/faq",
    },
    {
      text: "Mentions légales",
      url: "/mentions-legales",
    },
    {
      text: "Conditions générales d'utilisation",
      url: "/cgu",
    },
    {
      text: "Nous rejoindre",
      url: "https://get.elinoi.com/elinoi-entreprise",
      externe: true,
    },
  ];

  return (
    <footer className={styles.footer}>
      <div className={cx(global.container, styles.footerContainer)}>
        <div className={styles.footerLinkContainer}>
          <h2 className={cx(styles.logo)}>
            <NavLink
              to="/"
              onClick={() => {
                sendGA("click", {
                  event_label: "footer - home",
                });
              }}
            >
              <span className={global.visuallyHidden}>elinoï</span>
              <Logo />
            </NavLink>
          </h2>
          <div className={styles.textContent}>
            <div>
              <div className={styles.taglineContainer}>
                <h3>L’incubateur de talents qui vous aide à trouver votre futur job en startup.</h3>
              </div>
              <ul className={styles.rsList}>
                {rs.map((it, i) => {
                  return (
                    <li key={`rs-${i}`} className={styles.rsItem}>
                      <SocialIcon item={it} />
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={cx(styles.footerNav, global.small)}>
              <div>
                <h4>
                  <a href={`${blogNav.mainUrl}`} target="_blank" rel="noreferrer noopener">
                    {blogNav.title}
                  </a>
                </h4>
                <ul>
                  {blogNav.links.map((it, i) => {
                    return (
                      <li key={`bl-${i}`}>
                        <a
                          href={`${blogNav.mainUrl}${it.url}`}
                          target="_blank"
                          rel="noreferrer noopener"
                          onClick={() => {
                            sendGA("click", {
                              event_label: `footer - blog - ${it.text}`,
                            });
                          }}
                        >
                          {it.text}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>
                <ul>
                  {siteNav.map((it, i) => {
                    return (
                      <li key={`st-${i}`}>
                        <NavLink
                          href={it.externe ? it.url : null}
                          to={it.externe ? null : it.url}
                          onClick={() => {
                            sendGA("click", {
                              event_label: `footer - ${it.text}`,
                            });
                          }}
                        >
                          {it.text}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerContainer}>
          <NewsletterForm />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
