/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { PropsWithChildren, useEffect } from "react";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ReactPixel from "react-facebook-pixel";
import LinkedInTag from "react-linkedin-insight";
import Cookies from "universal-cookie";

import LoginModal, { LoginModalClose, LoginModalOpen } from "../blocks/loginModal/loginModal";
import NewsletterModal, { NewsletterModalClose, NewsletterModalOpen } from "../blocks/newsletterModal/newsletterModal";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";

import Footer from "./footer/footer";
import Header from "./header/header";

eventDispatcher.set("_update");
eventDispatcher.set("_resize");
eventDispatcher.set("_navigate");
eventDispatcher.set("_closeMobileNav");
eventDispatcher.set("_initComponent");
eventDispatcher.set(LoginModalOpen);
eventDispatcher.set(LoginModalClose);
eventDispatcher.set(NewsletterModalOpen);
eventDispatcher.set(NewsletterModalClose);

export const cookies = new Cookies();

interface Props {
  location: Location;
}

if (typeof window !== "undefined") {
  ReactPixel.init(String(process.env.GATSBY_FACEBOOK_PIXELS_ID));
  LinkedInTag.init(process.env.GATSBY_LINKEDIN_PARTNER_ID);
}

const Layout: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const { location, children } = props;
  const requestRef = React.useRef(0);
  const alertOptions = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(_update);
    window.addEventListener("resize", _resize);
    _resize();

    return () => {
      window.removeEventListener("resize", _resize);
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const _update = () => {
    const _y = window.scrollY || window.pageYOffset;
    const wH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const wW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    eventDispatcher.trigger("_update", { _y, wH, wW });
    requestRef.current = requestAnimationFrame(_update);
  };

  const _resize = () => {
    const wW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const wH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    eventDispatcher.trigger("_resize", { wW, wH });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cookieOptions = {
      path: "/",
      domain: process.env.GATSBY_COOKIE_DOMAIN,
      httpOnly: false,
      secure: false,
    };
    if (params.has("rt")) {
      // param name for referral tokens
      cookies.set("referral", params.get("rt"), cookieOptions);
    }
    if (params.has("rf")) {
      // param name for shorten referral tokens
      cookies.set("shorten_link_referral", params.get("rf"), cookieOptions);
    }
    if (params.has("am")) {
      // param name for ambassador program
      cookies.set("ambassador", params.get("am"), cookieOptions);
    }

    const utmParams = ["utm_campaign", "utm_medium", "utm_source", "utm_term", "utm_content"];
    let utmCookie: { [key: string]: string | null } = {};
    utmParams.forEach((param) => {
      if (params.has(param)) {
        utmCookie[param] = params.get(param);
      }
    });
    if (Object.keys(utmCookie).length > 0) {
      cookies.set("utm", JSON.stringify(utmCookie), cookieOptions);
    }

    if (params.has("sign-up")) {
      eventDispatcher.trigger(LoginModalOpen);
    }
  }, [location]);

  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <Header />
      <main>{children}</main>
      <Footer />
      <LoginModal />
      {/* <NewsletterModal /> */}
    </AlertProvider>
  );
};

export default Layout;
