import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef, useState } from "react";

import { FaTimes } from "react-icons/fa";
import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";

import { NavLink } from "../../helpers/NavLink";
import { Logo } from "../../helpers/Svg";
import { sendGA } from "../../helpers/tracking";

import global from "../../scss/global.module.scss";
import LinkedInConnectCTA from "../linkedInCta/linkedInCta";
import styles from "./header.module.scss";

const { routes } = require("../../routes.ts");

const Header: React.FC = () => {
  const [burgerIsActive, setBurgerIsActive] = useState<boolean>(false);
  const navRef = useRef<MiniSignalBinding>();
  const closeMobileRef = useRef<MiniSignalBinding>();

  useEffect(() => {
    navRef.current = eventDispatcher.on("_navigate", onNavigate);
    closeMobileRef.current = eventDispatcher.on("_closeMobileNav", closeMobileMenu);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
      if (closeMobileRef.current) {
        eventDispatcher.off("_closeMobileNav", closeMobileRef.current);
      }
    };
  }, []);

  const closeMobileMenu = () => {
    setBurgerIsActive(false);
  };
  const onNavigate = (props: any) => {
    setBurgerIsActive(false);
  };

  const onClickBurger = () => {
    setBurgerIsActive(true);
  };

  const onClickLoginCta = () => {
    eventDispatcher.trigger("loginModal:Open");
  };

  const navItems = (
    <>
      <li className={styles.hasSubMenu}>
        <NavLink
          className={styles.link}
          to={routes.app.talent.path}
          onClick={() => {
            sendGA("click", {
              event_label: "header - talents",
            });
          }}
        >
          trouver un job
        </NavLink>
        <ul className={styles.subMenuList}>
          <li>
            <NavLink
              className={styles.link}
              to={routes.app.talent.path}
              activeClassName={styles.active}
              onClick={() => {
                sendGA("click", {
                  event_label: "header - etre accompagne",
                });
              }}
            >
              Être accompagné
            </NavLink>
          </li>
          <li>
            <NavLink
              className={styles.link}
              href="https://pimp-formation-elinoi.umso.co/"
              activeClassName={styles.active}
              onClick={() =>
                sendGA("click", {
                  event_label: "header - formation aux entretiens",
                })
              }
            >
              Briller en entretien
            </NavLink>
          </li>
        </ul>
      </li>
      <li className={styles.hasSubMenu}>
        <NavLink
          className={styles.link}
          to={routes.app.recruter.path}
          onClick={() => {
            sendGA("click", {
              event_label: "header - startups",
            });
          }}
        >
          recruter
        </NavLink>
        <ul className={styles.subMenuList}>
          <li>
            <NavLink
              className={styles.link}
              to={routes.app.recruter.path}
              activeClassName={styles.active}
              onClick={() => {
                sendGA("click", {
                  event_label: "header - recrutement",
                });
              }}
            >
              Recruter avec elinoi
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              className={styles.link}
              to="/offboarding/"
              activeClassName={styles.active}
              onClick={() =>
                sendGA("click", {
                  event_label: "header - offboarding",
                })
              }
            >
              Offboarding
            </NavLink>
          </li> */}
          <li>
            <NavLink
              className={styles.link}
              href="https://get.elinoi.com/stamp-formation-recrutement"
              onClick={() =>
                sendGA("click", {
                  event_label: "header - STAMP apprendre à recruter",
                })
              }
            >
              Se former au recrutement
            </NavLink>
          </li>
        </ul>
      </li>
      <li className={styles.hasSubMenu}>
        <NavLink
          className={styles.link}
          to={routes.app.aboutUs.path}
          onClick={() => {
            sendGA("click", {
              event_label: "header - notre mission",
            });
          }}
        >
          notre mission
        </NavLink>
        <ul className={styles.subMenuList}>
          <li>
            <NavLink
              className={styles.link}
              to={routes.app.aboutUs.path}
              activeClassName={styles.active}
              onClick={() => {
                sendGA("click", {
                  event_label: "header - notre mission",
                });
              }}
            >
              Qui nous sommes
            </NavLink>
          </li>
          <li>
            <NavLink
              className={styles.link}
              href="https://get.elinoi.com/elinoi-entreprise"
              activeClassName={styles.active}
              onClick={() =>
                sendGA("click", {
                  event_label: "header - nous rejoindre",
                })
              }
            >
              Nous rejoindre
            </NavLink>
          </li>
        </ul>
      </li>
      <li className={styles.hasSubMenu}>
        <span className={styles.link}>apprendre</span>
        <ul className={styles.subMenuList}>
          <li>
            <NavLink
              className={styles.link}
              href="https://blog.elinoi.com/"
              activeClassName={styles.active}
              onClick={() =>
                sendGA("click", {
                  event_label: "header - Le blog",
                })
              }
            >
              Le blog
            </NavLink>
          </li>
          <li>
            <NavLink
              className={styles.link}
              href="https://metiers-startup.elinoi.com/"
              activeClassName={styles.active}
              onClick={() =>
                sendGA("click", {
                  event_label: "header - le livre blanc",
                })
              }
            >
              Les métiers en startup
            </NavLink>
          </li>
          <li>
            <NavLink
              className={styles.link}
              to="/simulateur-de-salaire"
              activeClassName={styles.active}
              onClick={() =>
                sendGA("click", {
                  event_label: "header - simulateur de salaire",
                })
              }
            >
              Les salaires en startup
            </NavLink>
          </li>
          <li>
            <NavLink
              className={styles.link}
              href="https://get.elinoi.com/50-startups-ou-postuler-en-2022"
              activeClassName={styles.active}
              onClick={() =>
                sendGA("click", {
                  event_label: "header - les 50 startups 2022",
                })
              }
            >
              Où postuler en 2022 ?
            </NavLink>
          </li>
        </ul>
      </li>
    </>
  );

  return (
    <header>
      {/* <div className={styles.announcementBanner}>
        <NavLink
          href="https://pimp-formation-elinoi.umso.co/"
          onClick={() => {
            sendGA("click", {
              event_label: "header - formation banner",
            });
          }}
        >
          <div className={styles.announcementWordingContainer}>
            <span className={styles.announcementWording}>
              Suivez notre formation PIMP, et faites-vous recruter à coup sûr
            </span>
            <span className={styles.announcementWording}>
              Suivez notre formation PIMP, et faites-vous recruter à coup sûr
            </span>
            <span className={styles.announcementWording}>
              Suivez notre formation PIMP, et faites-vous recruter à coup sûr
            </span>
            <span className={styles.announcementWording}>
              Suivez notre formation PIMP, et faites-vous recruter à coup sûr
            </span>
          </div>
        </NavLink>
      </div> */}
      <div className={global.container}>
        <div className={styles.headerContent}>
          <div className={cx(styles.logo)}>
            <h1>
              <NavLink
                to="/"
                onClick={() => {
                  sendGA("click", {
                    event_label: "header - home",
                  });
                }}
              >
                <Logo className={styles.headerLogo} />
              </NavLink>
            </h1>
          </div>
          <div>
            <nav className={styles.nav}>
              <ul className={styles.navList}>
                {navItems}
                <li>
                  <span
                    className={styles.link}
                    onClick={() => {
                      onClickLoginCta();
                      sendGA("click", {
                        event_label: "header - ouverture modale - s'inscrire",
                      });
                    }}
                  >
                    {"s'inscrire"}
                  </span>
                </li>
                <li className={styles.hasSubMenu}>
                  <span className={styles.link}>se connecter</span>
                  <ul className={styles.subMenuList}>
                    <li>
                      <LinkedInConnectCTA
                        renderElement={({ onClick }) => (
                          <span
                            onClick={() => {
                              sendGA("click", {
                                event_label: "connexion - talent",
                              });
                              onClick();
                            }}
                            className={styles.link}
                          >
                            vous êtes un talent
                          </span>
                        )}
                      />
                    </li>
                    <li>
                      <NavLink
                        className={styles.link}
                        href={`${process.env.GATSBY_CLIENT_BASE_URL}`}
                        activeClassName={styles.active}
                        onClick={() =>
                          sendGA("click", {
                            event_label: "connexion - client",
                          })
                        }
                      >
                        vous êtes un client
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <div className={styles.burgerContainer}>
              <div className={styles.burger} onClick={onClickBurger}>
                <span className={styles.lineTop}></span>
                <span className={styles.lineBottom}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.navMobileContainer}>
        <div
          className={cx(styles.navMobile, {
            [styles.menuActive]: burgerIsActive,
          })}
        >
          <div className={styles.halfCircle}></div>
          <div className={styles.donut}></div>
          <div className={styles.circle}></div>
          <span className={styles.close} onClick={closeMobileMenu}>
            <FaTimes />
          </span>
          <div className={styles.listMobileContainer}>
            <ul className={styles.navListMobile}>{navItems}</ul>
            <ul className={styles.logListMobile}>
              <li>
                <span
                  onClick={() => {
                    onClickLoginCta();
                    sendGA("click", {
                      event_label: "menu mobile - ouverture modale - m'inscrire",
                    });
                  }}
                  className={styles.link}
                >
                  {"S'inscrire"}
                </span>
              </li>
              <li>
                <span
                  onClick={() => {
                    onClickLoginCta();
                    sendGA("click", {
                      event_label: "menu mobile - ouverture modale - me connecter",
                    });
                  }}
                  className={styles.link}
                >
                  Me connecter
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
