import cx from "classnames";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

import * as yup from "yup";
import { ErrorMessage } from "../../components/Formik/ErrorMessage";
import { Input } from "../../components/Formik/Input";
import global from "../../scss/global.module.scss";
import { postNewMailjetSubscription } from "../../utils/api";
import styles from "./newsletterForm.module.scss";

interface FormValues {
  email: string;
  newsletter: string;
}

export const NewsletterModalOpen = "newsModal:Open";
export const NewsletterModalClose = "newsModal:Close";

export const NewsletterForm: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const $formSubmitBtns = useRef(null);
  const initStateRef = useRef<NodeJS.Timeout | null>(null);
  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    newsletter: yup.string().required(),
  });
  const initialValues: FormValues = {
    email: "",
    newsletter: "",
  };
  const onSubmitForm = (values: FormValues) => {
    setIsSubmitting(true);
    setSubmitError(false);
    postNewMailjetSubscription(values)
      .then((value: { status: number }) => {
        if (value.status === 200) {
          setIsSuccess(true);
          setIsSubmitting(false);
        }
      })
      .catch(() => {
        setIsSubmitting(false);
        setSubmitError(true);
      });
  };

  useEffect(() => {
    if (isSuccess) {
      initStateRef.current = setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }

    return () => {
      if (initStateRef.current) {
        clearTimeout(initStateRef.current);
      }
    };
  }, [isSuccess]);

  return (
    <>
      <div className={styles.newsletterContainer}>
        <h2 className={styles.title}>La newsletter transparente sur le recrutement</h2>
        <div className={styles.text}>
          Des conseils, des histoires et des convictions. Le tout, toutes les 2 semaines dans votre boîte mail.
        </div>
        <Formik
          onSubmit={onSubmitForm}
          initialValues={{
            email: "",
            newsletter: undefined,
          }}
          validationSchema={validationSchema}
          validateOnChange={true}
        >
          {(form) => (
            <Form className={"form"}>
              <div className="form-row">
                <div className="form-group">
                  <div className="form-group-content">
                    <Field
                      component={Select}
                      name="newsletter"
                      classNamePrefix="multiSelect"
                      isSearchable={false}
                      showErrorMessage={true}
                      onChange={(el: { label: string; value: string }) => {
                        form.setFieldValue("newsletter", el.value);
                      }}
                      placeholder="Vous êtes.."
                      options={[
                        { value: "talent", label: "Un.e talent" },
                        { value: "btob", label: "Une entreprise ou recruteur" },
                      ]}
                    />
                    <ErrorMessage name={"newsletter"} />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <div className="form-group-content">
                    <Field component={Input} name="email" type="email" placeholder="Votre email" />
                  </div>
                </div>
              </div>
              <div className={cx(styles.text, styles.rgpd)}>
                Votre adresse mail ne sera jamais au grand jamais communiquée à l’extérieur et un lien de désabonnement
                sera présent dans toutes les newsletters.
              </div>
              <div className={styles.formSubmitBtnsContainer}>
                <div
                  className={cx(styles.formSubmitBtns, {
                    [styles.isSubmitting]: isSubmitting,
                    [styles.isSuccess]: isSuccess,
                  })}
                  ref={$formSubmitBtns}
                >
                  <span className={styles.formSuccessCta}>Inscription réussie !</span>
                  <span className={styles.formSubmittingCta}>Inscription en cours...</span>
                  <button className={cx(styles.formSubmitCta, global.cta, global.ctaPrimary)} type="submit">
                    S'inscrire
                  </button>
                </div>
              </div>
              {submitError ? <span className="form-error">Une erreur s'est produite.</span> : null}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
