const routes = {
  app: {
    home: {
      path: "/",
      priority: 1,
      changefreq: "daily",
    },
    talent: {
      path: "/vous-accompagner",
      priority: 1,
      changefreq: "monthly",
    },
    recruter: {
      path: "/vous-aider-a-recruter",
      priority: 1,
      changefreq: "monthly",
    },
    aboutUs: {
      path: "/notre-mission",
      priority: 0.8,
      changefreq: "monthly",
    },
    contactUs: {
      path: "/nous-contacter",
      priority: 0.7,
      hideFromSitemap: true,
    },
    faq: { path: "/faq", hideFromSitemap: true },
    cgu: { path: "/cgu", hideFromSitemap: true },
    loginCheck: { path: "/login-check", hideFromSitemap: true },
    mentionsLegales: { path: "/mentions-legales", hideFromSitemap: true },
    offboarding: { path: "/offboarding", hideFromSitemap: true },
    simulateurDeSalaire: { path: "/simulateur-de-salaire", hideFromSitemap: true },
    signupCompany: {
      path: "/sign_up/company",
      hideFromSitemap: true,
    },
  },
};

module.exports = { routes };
