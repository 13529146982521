import anime from "animejs";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";

import { FaTimes } from "react-icons/fa";
import LinkedInConnectCTA from "../../components/linkedInCta/linkedInCta";
import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import { sendGA } from "../../helpers/tracking";
import global from "../../scss/global.module.scss";
import styles from "./loginModal.module.scss";

export const LoginModalOpen = "loginModal:Open";
export const LoginModalClose = "loginModal:Close";

const LoginModal: React.FC = () => {
  const openModalRef = useRef<MiniSignalBinding>();
  const closeModalRef = useRef<MiniSignalBinding>();
  const $wrapper = useRef(null);
  const $modal = useRef(null);
  const $modalContent = useRef(null);
  const $closeCta = useRef(null);
  const isAnimating = useRef(false);
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    openModalRef.current = eventDispatcher.on(LoginModalOpen, onOpenModal);
    closeModalRef.current = eventDispatcher.on(LoginModalClose, onCloseModal);

    return () => {
      if (openModalRef.current) {
        eventDispatcher.off(LoginModalOpen, openModalRef.current);
      }
      if (closeModalRef.current) {
        eventDispatcher.off(LoginModalClose, closeModalRef.current);
      }
    };
  }, []);

  const onOpenModal = () => {
    if (isAnimating.current) {
      return;
    }
    document.body.style.overflow = "hidden";
    isAnimating.current = true;
    setIsHidden(false);
    sendGA("page", {
      event_category: "modale",
      event_label: "global - ouverture modale",
    });

    const tl = anime.timeline({
      delay: 100,
      duration: 200,
      easing: "easeInOutSine",
      complete: () => {
        isAnimating.current = false;
      },
    });

    const d1 = 400;
    const ease1 = "easeOutSine";

    tl.add({
      opacity: [0, 1],
      targets: $wrapper.current,
    })
      .add({
        opacity: [0, 1],
        translateY: [-20, 0],
        duration: d1,
        easing: ease1,
        targets: $modal.current,
      })
      .add(
        {
          translateY: [20, 0],
          duration: d1,
          easing: ease1,
          targets: $modalContent.current,
        },
        300,
      )
      .add(
        {
          translateY: [10, 0],
          opacity: [0, 1],
          duration: d1,
          targets: $closeCta.current,
        },
        "-=500",
      );
  };

  const onCloseModal = () => {
    if (isAnimating.current) {
      return;
    }
    isAnimating.current = true;
    const tl = anime.timeline({
      delay: 100,
      duration: 300,
      easing: "easeInOutSine",
      complete: () => {
        isAnimating.current = false;
        document.body.style.overflow = "";
        setIsHidden(true);
      },
    });

    const d1 = 300;
    const ease1 = "easeInSine";

    tl.add({
      duration: d1,
      opacity: 0,
      translateY: 20,
      easing: ease1,
      targets: $modal.current,
    })
      .add(
        {
          duration: d1,
          translateY: -20,
          easing: ease1,
          targets: $modalContent.current,
        },
        0,
      )
      .add(
        {
          translateY: 10,
          opacity: 0,
          duration: d1,
          targets: $closeCta.current,
        },
        0,
      )
      .add({
        opacity: 0,
        targets: $wrapper.current,
      });
  };

  const onClickWrapper = (e: SyntheticEvent) => {
    const currentTarget = e.target;
    if ($wrapper.current === currentTarget) {
      closeModal();
    }
  };

  const closeModal = () => {
    eventDispatcher.trigger(LoginModalClose);
    sendGA("click", {
      event_label: "modale - fermer",
    });
  };

  return (
    <div className={cx(styles.modalWrapper, { [styles.hidden]: isHidden })} ref={$wrapper} onClick={onClickWrapper}>
      <span className={styles.close} ref={$closeCta} onClick={closeModal}>
        <FaTimes />
      </span>
      <div className={styles.modal} ref={$modal}>
        <div ref={$modalContent}>
          <p className={styles.text}>
            Chez elinoï, on ne vous demande ni CV ni lettre de motivation. Connectez-vous simplement avec votre compte
            Linkedin, il suffit d’un clic.
          </p>
          <div className={styles.modalTextCtaContainer}>
            <span className={cx(styles.title, global.title)}>
              Faisons au <strong>plus simple</strong> :
            </span>
            <div>
              <LinkedInConnectCTA
                renderElement={({ onClick }) => (
                  <span
                    onClick={() => {
                      onClick();
                      sendGA("click", {
                        event_label: "modale - connexion",
                      });
                    }}
                    className={styles.linkedInCta}
                  >
                    Linkedin
                  </span>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
