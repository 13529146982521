const config = {
  siteAuthor: "elinoï",
  siteTitle: "elinoï - Votre futur job parmi plus de 200 startups", // Site title.
  siteTitleShort: "elinoï", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "elinoï - Votre futur job parmi plus de 200 startups", // Alternative site title for SEO.
  titleTemplate: "%s - elinoï",
  siteLogo: "src/assets/images/favicon.png", // Logo used for SEO and manifest.
  siteUrl: "https://elinoi.com", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    "elinoï est un incubateur de talents qui vous aide à trouver votre futur job en startup. Business development, marketing, produit, opérations : inscrivez-vous en 5 minutes pour découvrir nos offres de CDI.", // Website description used for meta description tag.
  siteFBAppID: "579711872465743", // FB Application ID for using app insights
  googleAnalyticsID: "UA-44095927-1", // GA tracking ID.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  themeColor: "#5010f2", // Used for setting manifest and progress theme colors.
  backgroundColor: "#5010f2", // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/") config.siteUrl = config.siteUrl.slice(0, -1);

module.exports = { config };
