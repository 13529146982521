import client from "./httpClient";
import { GetResponsibilities, IGetPublishedReviews, IResponsibility } from "./interfaces";

export const login = (code: string, redirectUri: string) => {
  return client.post("/api/v2/userspace/auth/login", { code, redirectUri }, { withCredentials: true });
};

export const getResponsibilities: GetResponsibilities = (callback) => {
  client
    .get("/api/v2/responsibilities?active=1")
    .then((response) => {
      const responsibilities: IResponsibility[] = response.data["hydra:member"].reduce(
        (result: { label: string; options: IResponsibility[] }[], item: IResponsibility) => {
          const responsibilityType = item.responsibilityType;
          if (result.some((e) => e.label === responsibilityType.value)) {
            result.map((e) => {
              if (e.label === responsibilityType.value) {
                e.options.push(item);
              }
              return true;
            });
          } else {
            result.push({
              label: responsibilityType.value,
              options: [item],
            });
          }
          return result;
        },
        [],
      );
      const arg = { state: "success", responsibilities };
      return typeof callback === "function" ? callback(arg) : arg;
    })
    .catch(() => {
      const arg = { state: "error", responsibilities: [] };
      return typeof callback === "function" ? callback(arg) : arg;
    });
};

export const postNewContact = (values: any): Promise<{}> => {
  return client.post("/api/v2/companies/incoming_lead", {
    creationDate: values.creationDate ? values.creationDate : undefined,
    ...values,
  });
};

export const getPublishedReviews: IGetPublishedReviews = (callback) => {
  client
    .get("/asknicely/get_testimonials")
    .then((response) => {
      const reviews = response.data.data;
      const arg = { state: "success", reviews };
      return typeof callback === "function" ? callback(arg) : arg;
    })
    .catch(() => {
      const arg = { state: "error", reviews: [] };
      return typeof callback === "function" ? callback(arg) : arg;
    });
};

export const postNewMailjetSubscription = (values: {
  email: string;
  newsletter: string;
}): Promise<{ status: number }> => {
  return client.post("/mailjet/", values);
};
