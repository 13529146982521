import anime from "animejs";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";

import { FaTimes } from "react-icons/fa";
import Select from "react-select";
import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import global from "../../scss/global.module.scss";
import styles from "./newsletterModal.module.scss";

export const NewsletterModalOpen = "newsModal:Open";
export const NewsletterModalClose = "newsModal:Close";

const NewsletterModal: React.FC = () => {
  const openModalRef = useRef<MiniSignalBinding>();
  const closeModalRef = useRef<MiniSignalBinding>();
  const $wrapper = useRef(null);
  const $modal = useRef(null);
  const $modalContent = useRef(null);
  const $closeCta = useRef(null);
  const isAnimating = useRef(false);
  const [isHidden, setIsHidden] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [switchChoice, setSwitchChoice] = useState("talent");
  const shouldOpen = useRef(true);
  useEffect(() => {
    openModalRef.current = eventDispatcher.on(NewsletterModalOpen, onOpenModal);
    closeModalRef.current = eventDispatcher.on(NewsletterModalClose, onCloseModal);

    return () => {
      if (openModalRef.current) {
        eventDispatcher.off(NewsletterModalOpen, openModalRef.current);
      }
      if (closeModalRef.current) {
        eventDispatcher.off(NewsletterModalClose, closeModalRef.current);
      }
    };
  }, []);

  const onOpenModal = () => {
    if (isAnimating.current || !shouldOpen.current) {
      return;
    }
    document.body.style.overflow = "hidden";
    isAnimating.current = true;
    shouldOpen.current = false;
    setIsHidden(false);

    const tl = anime.timeline({
      delay: 100,
      duration: 200,
      easing: "easeInOutSine",
      complete: () => {
        isAnimating.current = false;
      },
    });

    const d1 = 400;
    const ease1 = "easeOutSine";

    tl.add({
      opacity: [0, 1],
      targets: $wrapper.current,
    })
      .add({
        opacity: [0, 1],
        translateY: [-20, 0],
        duration: d1,
        easing: ease1,
        targets: $modal.current,
      })
      .add(
        {
          translateY: [20, 0],
          duration: d1,
          easing: ease1,
          targets: $modalContent.current,
        },
        300,
      )
      .add(
        {
          translateY: [10, 0],
          opacity: [0, 1],
          duration: d1,
          targets: $closeCta.current,
        },
        "-=500",
      );
  };

  const onCloseModal = () => {
    if (isAnimating.current) {
      return;
    }
    isAnimating.current = true;
    const tl = anime.timeline({
      delay: 100,
      duration: 300,
      easing: "easeInOutSine",
      complete: () => {
        isAnimating.current = false;
        document.body.style.overflow = "";
        setIsHidden(true);
      },
    });

    const d1 = 300;
    const ease1 = "easeInSine";

    tl.add({
      duration: d1,
      opacity: 0,
      translateY: 20,
      easing: ease1,
      targets: $modal.current,
    })
      .add(
        {
          duration: d1,
          translateY: -20,
          easing: ease1,
          targets: $modalContent.current,
        },
        0,
      )
      .add(
        {
          translateY: 10,
          opacity: 0,
          duration: d1,
          targets: $closeCta.current,
        },
        0,
      )
      .add({
        opacity: 0,
        targets: $wrapper.current,
      });
  };

  const onClickWrapper = (e: SyntheticEvent) => {
    const currentTarget = e.target;
    if ($wrapper.current === currentTarget) {
      closeModal();
    }
  };

  const closeModal = () => {
    eventDispatcher.trigger(NewsletterModalClose);
  };

  return (
    <div className={cx(styles.modalWrapper, { [styles.hidden]: isHidden })} ref={$wrapper} onClick={onClickWrapper}>
      <span className={styles.close} ref={$closeCta} onClick={closeModal}>
        <FaTimes />
      </span>
      <div className={styles.modal} ref={$modal}>
        <div ref={$modalContent}>
          <h2 className={cx(styles.title, global.title)}>
            Pas si vite ! <br />
            Vous connaissez <strong>notre newsletter</strong> ?
          </h2>
          <p className={styles.text}>
            Des conseils, des histoires et des convictions. Le tout, toutes les 2 semaines dans votre boîte mail.
          </p>
          <div className={styles.selectIframeContainer}>
            <div className={styles.selectContainer}>
              <Select
                isSearchable={false}
                placeholder={"Vous êtes.."}
                options={[
                  { value: "talent", label: "Un.e talent" },
                  { value: "company", label: "Une entreprise ou recruteur" },
                ]}
                onChange={(val: any) => {
                  if (val) {
                    setSwitchChoice(val.value);
                    setIsDisabled(false);
                  }
                }}
              />
            </div>
            <div className={cx(styles.iframesSwitch, { [styles.disabled]: isDisabled })}>
              <div className={cx(styles.iframeContainer, { [styles.isVisible]: switchChoice === "talent" })}>
                <iframe
                  className="mj-w-res-iframe"
                  frameBorder="0"
                  // scrolling="no"
                  src="https://app.mailjet.com/widget/iframe/4Dj4/FHt"
                  width="100%"
                ></iframe>
              </div>
              <div className={cx(styles.iframeContainer, { [styles.isVisible]: switchChoice === "company" })}>
                <iframe
                  className="mj-w-res-iframe"
                  frameBorder="0"
                  // scrolling="no"
                  src="https://app.mailjet.com/widget/iframe/4Dj4/FBf"
                  width="100%"
                ></iframe>
              </div>
            </div>
            <span className={cx(styles.text, styles.disclamer)}>
              Votre adresse mail ne sera jamais au grand jamais communiquée à l’extérieur et un lien de désabonnement
              sera présent dans toutes les newsletters.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterModal;
