import React from "react";
const { config } = require("./../../config.ts");

interface ISvgProps {
  className?: string;
}

const Svg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
      <symbol id="logo" viewBox="0 0 680.7 180.4">
        <path
          className="path-fill"
          fill="#303030"
          d="M111.5 125.8H35c3.6 20 20 22.1 30.1 22.1h33.8l13.1-.1v31.6h-3.8c-7.5 0-26.7-.2-46.1-.2-37.7.1-62.1-24.5-62.1-62.6C0 77.4 24.5 50 59.6 50c36.9 0 53.4 27.8 53.4 57.3 0 3.9-.4 9-.6 11.1m-76-21.7h41.4c-.8-4.1-2.4-9-6-12.5-3.4-3.4-7.9-5-13.9-5-13-.1-18.9 9.9-21.5 17.5M185.1 179.5h-35.6l-.3-152.2L146.5 1h37.9zM294 179.5l.6-34.9V90.4c0-12.3-.3-21.1-.9-29.5l-.6-11.8h33l.5 11.3c7.6-5.9 17.9-10.1 30.8-10.1 21.6 0 46.7 14.3 46.7 54.8l.6 74.4H369l-.1-72.1c0-25.3-13.7-25.3-18.2-25.3-10.5 0-17.6 7.7-19.9 15.3l-.1.4c-.6 1.6-.9 4.2-.9 6.8l-.1 74.8-35.7.1zM500.9 181.4c-35.8 0-60.7-26.6-60.7-64.6 0-39.1 23.9-66.5 60.7-66.5 35.9 0 62.8 26.6 62.8 64.6 0 21-6.9 38.4-20 50.4-11.3 10.4-26.5 16.1-42.8 16.1m1.8-116.1c-28.4 0-47.5 20.7-47.5 51.4 0 29.7 18.4 49.6 45.7 49.6 33 0 47.8-25.8 47.8-51.5-.1-29.5-18.5-49.5-46-49.5m-.4 110.4c-23.9 0-52.5-31-52.5-60.1 0-24.4 22-56.3 50.7-56.3 26.8 0 54.4 31.4 54.4 56.3-.1 28.9-29.1 60.1-52.6 60.1m-.1-95.2c-12.3 0-26.7 9.3-26.7 35.6 0 20.4 11 35.2 26.2 35.2s26.2-15 26.2-35.6c.1-12.2-5.3-35.2-25.7-35.2"
        />
        <path
          className="path-primary-fill"
          fill="#5010F2"
          d="M240.3 51.9c-10.8 0-19.2-8.4-19.2-19.2 0-10.6 8.6-19.2 19.2-19.2 10.5 0 18.7 8.4 18.7 19.2.1 10.7-8.2 19.2-18.7 19.2"
        />
        <path
          className="path-primary-fill"
          fill="#5010F2"
          d="M680.7 12c0 10.8-8.3 19.3-18.8 19.3-10.7 0-19-8.5-19-19.3"
        />
        <path
          className="path-fill"
          fill="#303030"
          d="M633.7 179.5H598l.2-130.4h35.2zM258.3 179.5h-35.6l-.2-130.4h35.2z"
        />
        <path
          fill="#5010f1"
          d="M616 25.1c4 0 7.3 3.3 7.3 7.3s-3.3 7.3-7.3 7.3-7.3-3.3-7.3-7.3 3.3-7.3 7.3-7.3m0-10c-9.6 0-17.3 7.7-17.3 17.3s7.7 17.3 17.3 17.3 17.3-7.7 17.3-17.3-7.7-17.3-17.3-17.3z"
        />
        <path
          className="path-stroke"
          fill="none"
          d="M615.9 47c-8 0-14.3-6.3-14.3-14.3 0-7.9 6.4-14.3 14.3-14.3 7.8 0 14 6.3 14 14.3s-6.1 14.3-14 14.3z"
          stroke="#5010f2"
          strokeWidth="10"
          strokeMiterlimit="10"
        />
      </symbol>
      <symbol id="logoSmall" viewBox="0 0 945 289.45">
        <path
          className="path"
          d="M0 133c1.66-7.51 3.05-15.09 5-22.51a132.4 132.4 0 0 1 25.78-49.86c13.19-16.17 29-28.89 48-37.52a140.65 140.65 0 0 1 48.39-12.34A134.1 134.1 0 0 1 182 18a126.63 126.63 0 0 1 44.46 26.81 130 130 0 0 1 31 42.28c6 13.2 10.68 26.9 11.49 41.55.58 10.58 1.74 21.27.83 31.75a136.45 136.45 0 0 1-22.67 65.08 129.92 129.92 0 0 1-53.1 45.9c-13.87 6.6-28.54 10.24-44 11.8a136.53 136.53 0 0 1-76.42-14.07c-23.74-11.89-42.08-29.76-55.49-52.67A123.47 123.47 0 0 1 3 175.62c-.88-4.9-2-9.75-3-14.62zM945 17c-1.33 6.12-2.57 12.26-4 18.35a131.29 131.29 0 0 1-26.11 53.3c-11.92 14.77-26.07 26.87-43.06 35.27a135.81 135.81 0 0 1-45.57 13.26c-22.5 2.5-44.09-.57-65.08-8.3-13.35-4.92-25.32-12.45-36.37-21.5-21-17.21-34.88-39-42.94-64.78A128 128 0 0 1 676 0h269zM485.09 289.45c-31.62 0-60.37-9.21-85.58-27.84-27-20-44.5-46.85-52.53-79.67a141.6 141.6 0 0 1-3.86-44.08c2.15-29.26 11.95-55.79 30.39-78.85C386.82 42.38 402.84 28.93 422 19.6A144.55 144.55 0 0 1 535.1 14a134.79 134.79 0 0 1 40.17 23.64 132.71 132.71 0 0 1 35.17 45.45c6.38 13.36 11.22 27.34 12.54 42.26.82 9.17 2.3 18.42 1.84 27.55-1.3 25.72-8 49.9-22 71.94a136.82 136.82 0 0 1-62 53.17 119.81 119.81 0 0 1-38.48 10q-8.63.71-17.25 1.44zm-.16-81.45c34.19.79 58.68-27.18 58.89-59.56.24-36.26-24.07-61-56.67-62.38-35.19-1.43-62.26 28.16-63.2 59.1-1.08 35.66 27.15 63.84 60.98 62.84z"
        />
      </symbol>
    </svg>
  );
};

export const Logo = ({ className }: ISvgProps) => (
  <svg className={className} viewBox="0 0 680.7 180.4">
    <title>{config.siteTitle}</title>
    <path
      className="path-fill"
      fill="#303030"
      d="M111.5 125.8H35c3.6 20 20 22.1 30.1 22.1h33.8l13.1-.1v31.6h-3.8c-7.5 0-26.7-.2-46.1-.2-37.7.1-62.1-24.5-62.1-62.6C0 77.4 24.5 50 59.6 50c36.9 0 53.4 27.8 53.4 57.3 0 3.9-.4 9-.6 11.1m-76-21.7h41.4c-.8-4.1-2.4-9-6-12.5-3.4-3.4-7.9-5-13.9-5-13-.1-18.9 9.9-21.5 17.5M185.1 179.5h-35.6l-.3-152.2L146.5 1h37.9zM294 179.5l.6-34.9V90.4c0-12.3-.3-21.1-.9-29.5l-.6-11.8h33l.5 11.3c7.6-5.9 17.9-10.1 30.8-10.1 21.6 0 46.7 14.3 46.7 54.8l.6 74.4H369l-.1-72.1c0-25.3-13.7-25.3-18.2-25.3-10.5 0-17.6 7.7-19.9 15.3l-.1.4c-.6 1.6-.9 4.2-.9 6.8l-.1 74.8-35.7.1zM500.9 181.4c-35.8 0-60.7-26.6-60.7-64.6 0-39.1 23.9-66.5 60.7-66.5 35.9 0 62.8 26.6 62.8 64.6 0 21-6.9 38.4-20 50.4-11.3 10.4-26.5 16.1-42.8 16.1m1.8-116.1c-28.4 0-47.5 20.7-47.5 51.4 0 29.7 18.4 49.6 45.7 49.6 33 0 47.8-25.8 47.8-51.5-.1-29.5-18.5-49.5-46-49.5m-.4 110.4c-23.9 0-52.5-31-52.5-60.1 0-24.4 22-56.3 50.7-56.3 26.8 0 54.4 31.4 54.4 56.3-.1 28.9-29.1 60.1-52.6 60.1m-.1-95.2c-12.3 0-26.7 9.3-26.7 35.6 0 20.4 11 35.2 26.2 35.2s26.2-15 26.2-35.6c.1-12.2-5.3-35.2-25.7-35.2"
    />
    <path
      className="path-primary-fill"
      fill="#5010F2"
      d="M240.3 51.9c-10.8 0-19.2-8.4-19.2-19.2 0-10.6 8.6-19.2 19.2-19.2 10.5 0 18.7 8.4 18.7 19.2.1 10.7-8.2 19.2-18.7 19.2"
    />
    <path className="path-primary-fill" fill="#5010F2" d="M680.7 12c0 10.8-8.3 19.3-18.8 19.3-10.7 0-19-8.5-19-19.3" />
    <path
      fill="#5010f1"
      d="M616 25.1c4 0 7.3 3.3 7.3 7.3s-3.3 7.3-7.3 7.3-7.3-3.3-7.3-7.3 3.3-7.3 7.3-7.3m0-10c-9.6 0-17.3 7.7-17.3 17.3s7.7 17.3 17.3 17.3 17.3-7.7 17.3-17.3-7.7-17.3-17.3-17.3z"
    />
    <path
      className="path-stroke"
      fill="none"
      d="M615.9 47c-8 0-14.3-6.3-14.3-14.3 0-7.9 6.4-14.3 14.3-14.3 7.8 0 14 6.3 14 14.3s-6.1 14.3-14 14.3z"
      stroke="#5010f2"
      strokeWidth="10"
      strokeMiterlimit="10"
    />
    <path className="path-fill" fill="#303030" d="M633.7 179.5H598l.2-130.4h35.2zM258.3 179.5h-35.6l-.2-130.4h35.2z" />
  </svg>
);
export const LogoSmall = ({ className }: ISvgProps) => (
  <svg className={className} viewBox="0 0 945 289.45">
    <path
      className="path-fill"
      fill="#303030"
      d="M0 133c1.66-7.51 3.05-15.09 5-22.51a132.4 132.4 0 0 1 25.78-49.86c13.19-16.17 29-28.89 48-37.52a140.65 140.65 0 0 1 48.39-12.34A134.1 134.1 0 0 1 182 18a126.63 126.63 0 0 1 44.46 26.81 130 130 0 0 1 31 42.28c6 13.2 10.68 26.9 11.49 41.55.58 10.58 1.74 21.27.83 31.75a136.45 136.45 0 0 1-22.67 65.08 129.92 129.92 0 0 1-53.1 45.9c-13.87 6.6-28.54 10.24-44 11.8a136.53 136.53 0 0 1-76.42-14.07c-23.74-11.89-42.08-29.76-55.49-52.67A123.47 123.47 0 0 1 3 175.62c-.88-4.9-2-9.75-3-14.62zM945 17c-1.33 6.12-2.57 12.26-4 18.35a131.29 131.29 0 0 1-26.11 53.3c-11.92 14.77-26.07 26.87-43.06 35.27a135.81 135.81 0 0 1-45.57 13.26c-22.5 2.5-44.09-.57-65.08-8.3-13.35-4.92-25.32-12.45-36.37-21.5-21-17.21-34.88-39-42.94-64.78A128 128 0 0 1 676 0h269zM485.09 289.45c-31.62 0-60.37-9.21-85.58-27.84-27-20-44.5-46.85-52.53-79.67a141.6 141.6 0 0 1-3.86-44.08c2.15-29.26 11.95-55.79 30.39-78.85C386.82 42.38 402.84 28.93 422 19.6A144.55 144.55 0 0 1 535.1 14a134.79 134.79 0 0 1 40.17 23.64 132.71 132.71 0 0 1 35.17 45.45c6.38 13.36 11.22 27.34 12.54 42.26.82 9.17 2.3 18.42 1.84 27.55-1.3 25.72-8 49.9-22 71.94a136.82 136.82 0 0 1-62 53.17 119.81 119.81 0 0 1-38.48 10q-8.63.71-17.25 1.44zm-.16-81.45c34.19.79 58.68-27.18 58.89-59.56.24-36.26-24.07-61-56.67-62.38-35.19-1.43-62.26 28.16-63.2 59.1-1.08 35.66 27.15 63.84 60.98 62.84z"
    />
  </svg>
);
export const LogoSmallOutline = ({ className }: ISvgProps) => (
  <svg className={className} viewBox="0 0 945 289.45">
    <path
      className="path-stroke"
      fill="none"
      strokeWidth="1"
      stroke="#303030"
      d="M0 133c1.66-7.51 3.05-15.09 5-22.51a132.4 132.4 0 0 1 25.78-49.86c13.19-16.17 29-28.89 48-37.52a140.65 140.65 0 0 1 48.39-12.34A134.1 134.1 0 0 1 182 18a126.63 126.63 0 0 1 44.46 26.81 130 130 0 0 1 31 42.28c6 13.2 10.68 26.9 11.49 41.55.58 10.58 1.74 21.27.83 31.75a136.45 136.45 0 0 1-22.67 65.08 129.92 129.92 0 0 1-53.1 45.9c-13.87 6.6-28.54 10.24-44 11.8a136.53 136.53 0 0 1-76.42-14.07c-23.74-11.89-42.08-29.76-55.49-52.67A123.47 123.47 0 0 1 3 175.62c-.88-4.9-2-9.75-3-14.62zM945 17c-1.33 6.12-2.57 12.26-4 18.35a131.29 131.29 0 0 1-26.11 53.3c-11.92 14.77-26.07 26.87-43.06 35.27a135.81 135.81 0 0 1-45.57 13.26c-22.5 2.5-44.09-.57-65.08-8.3-13.35-4.92-25.32-12.45-36.37-21.5-21-17.21-34.88-39-42.94-64.78A128 128 0 0 1 676 0h269zM485.09 289.45c-31.62 0-60.37-9.21-85.58-27.84-27-20-44.5-46.85-52.53-79.67a141.6 141.6 0 0 1-3.86-44.08c2.15-29.26 11.95-55.79 30.39-78.85C386.82 42.38 402.84 28.93 422 19.6A144.55 144.55 0 0 1 535.1 14a134.79 134.79 0 0 1 40.17 23.64 132.71 132.71 0 0 1 35.17 45.45c6.38 13.36 11.22 27.34 12.54 42.26.82 9.17 2.3 18.42 1.84 27.55-1.3 25.72-8 49.9-22 71.94a136.82 136.82 0 0 1-62 53.17 119.81 119.81 0 0 1-38.48 10q-8.63.71-17.25 1.44zm-.16-81.45c34.19.79 58.68-27.18 58.89-59.56.24-36.26-24.07-61-56.67-62.38-35.19-1.43-62.26 28.16-63.2 59.1-1.08 35.66 27.15 63.84 60.98 62.84z"
    />
  </svg>
);

export const QuoteIcon = ({ className }: ISvgProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 836.1 576">
    <path d="M526.1 401c1.9-8.2 3.4-16.6 5.5-24.7 5.4-20.3 14.9-38.4 28.2-54.8 14.5-17.8 31.8-31.7 52.7-41.2 16.8-7.7 34.5-12.2 53.1-13.5 20.9-1.5 40.7 1.2 60.3 8 18.2 6.4 34.5 16.2 48.9 29.4 14.4 13.3 26 28.7 34 46.4 6.6 14.5 11.7 29.5 12.6 45.6.7 11.6 1.9 23.4.9 34.9-2.2 25.8-10.3 49.7-24.9 71.5-14.8 22.2-34.4 39-58.3 50.4-15.3 7.2-31.3 11.2-48.3 13-29.6 3-57.5-2.3-83.9-15.5-26-13.1-46.2-32.7-60.9-57.9-8.1-13.9-13.7-28.9-16.6-44.8-1-5.4-2.2-10.7-3.3-16V401z" />
    <path d="M589.9 151.7C644.4 86.5 704 39.2 768.7 9.8l21.4 45.1c-52.7 30.2-97.6 67.4-134.9 111.6s-55.9 89.3-55.9 135.4c0 8.3 2.3 15.3 6.8 20.9 4.5 5.7 12.4 8.5 23.7 8.5 48.1-5.3 74.5-7.9 79-7.9 27.8 0 40.5-7 67.9 12.9 27.5 19.9 41.2 49.5 41.2 88.6 0 30.9-10.7 60.8-32.2 89.7-21.4 29-57.4 43.4-107.8 43.4-54.2 0-83.6-3.9-118.2-45.8-34.6-41.8-51.9-89.1-51.9-141.9.3-80.5 27.5-153.4 82.1-218.6zM31.4 400.4c1.9-8.2 3.4-16.6 5.5-24.7 5.4-20.3 14.9-38.4 28.2-54.8 14.5-17.8 31.8-31.7 52.7-41.2 16.8-7.7 34.5-12.2 53.1-13.5 20.9-1.5 40.7 1.2 60.3 8 18.2 6.4 34.5 16.2 48.9 29.4 14.4 13.3 26 28.7 34 46.4 6.6 14.5 11.7 29.5 12.6 45.6.7 11.6 1.9 23.4.9 34.9-2.2 25.8-10.3 49.7-24.9 71.5-14.8 22.2-34.4 39-58.3 50.4-15.3 7.2-31.3 11.2-48.3 13-29.6 3-57.5-2.3-83.9-15.5-26-13.1-46.2-32.7-60.9-57.9-8.1-13.9-13.7-28.9-16.6-44.8-1-5.4-2.2-10.7-3.3-16v-30.8z" />
    <path d="M95.2 151.1C149.7 85.9 209.3 38.6 274 9.2l21.4 45.1c-52.7 30.2-97.6 67.4-134.9 111.6s-55.9 89.3-55.9 135.4c0 8.3 2.3 15.3 6.8 20.9 4.5 5.7 12.4 8.5 23.7 8.5 48.1-5.3 74.5-7.9 79-7.9 27.8 0 40.5-7 67.9 12.9 27.5 19.9 41.2 49.5 41.2 88.6 0 30.9-10.7 60.8-32.2 89.7-21.4 29-57.4 43.4-107.8 43.4-54.2 0-83.6-3.9-118.2-45.8-34.3-41.6-51.6-88.9-51.6-141.7 0-80.6 27.2-153.6 81.8-218.8z" />
  </svg>
);
