import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import { ErrorMessage } from "../ErrorMessage";

interface Props extends FieldProps {
  className?: string;
  label?: string | JSX.Element;
  id?: string;
  type: string;
  placeholder?: string;
  showErrorMessage?: boolean;
}

export const Input: React.FC<Props> = ({
  className,
  field,
  form: { setFieldTouched },
  label,
  id,
  type,
  placeholder,
  showErrorMessage = true,
}: Props) => {
  const [, meta] = useField(field.name);
  const [isFocused, setIsFocused] = React.useState(false);
  const [isNotEmpty, setIsNotEmpty] = React.useState(false);
  const fieldLabel =
    typeof label === "string" ? (
      <label className="label" htmlFor={field.name}>
        {label}
      </label>
    ) : (
      label
    );

  const onBlurInput = (e: React.FocusEvent) => {
    setIsNotEmpty(!!field.value);
    setFieldTouched(field.name, true);
    setIsFocused(false);
    field.onBlur(e);
  };
  const onFocusInput = () => {
    setIsFocused(true);
  };
  const onChangeInput = (e: React.SyntheticEvent) => {
    field.onChange(e);
  };

  return (
    <>
      <input
        name={field.name}
        className={cx(
          className,
          { isNotEmpty },
          { isFocused },
          { isDirty: meta.touched },
          { isNotValid: !!meta.error },
        )}
        type={type}
        id={id ? id : field.name}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        onChange={onChangeInput}
        placeholder={placeholder}
      />
      {fieldLabel}

      {showErrorMessage && <ErrorMessage name={field.name} />}
    </>
  );
};
