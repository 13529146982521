import ReactPixel from "react-facebook-pixel";

type WindowProps = Window &
  typeof globalThis & {
    lintrk: (eventType: string) => void;
    gtag: (eventType: string, action: { [key: string]: any }, data: { [key: string]: any }) => void;
  };

export const onInitPageLoadTracking = () => {
  const wdw = window as WindowProps;
  ReactPixel.pageView();
  if (typeof wdw !== "undefined" && wdw.lintrk) {
    wdw.lintrk("track");
  }
};

export const sendGA = (action, data) => {
  const wdw = window as WindowProps;
  if (typeof wdw !== "undefined" && wdw.gtag) {
    wdw.gtag("event", action, {
      event_category: "visiteur",
      ...data,
    });
  }
};
