import { Link, navigate } from "gatsby";
import React, { SyntheticEvent, useRef } from "react";
import eventDispatcher from "../eventDispatcher/eventDispatcher";

export interface NavigateData {
  currentTarget: EventTarget & Element;
  callback: () => void;
}

export interface INavLink {
  __html?: string;
  to?: string;
  href?: string;
  activeClassName?: string;
  className?: string;
  isExternal?: boolean;
  onClick?: () => void;
  title?: string;
}

export const NavLink: React.FC<INavLink> = (props) => {
  const {
    __html = "",
    to = "",
    href = "",
    activeClassName = "",
    children,
    className = "",
    isExternal = false,
    onClick,
    title = "",
  } = props;
  const externalLink = useRef<boolean>(isExternal);

  const onClickLink = (e: SyntheticEvent, l = "") => {
    const currentTarget = e.currentTarget;
    const callback = () => {
      navigate(l);
    };
    const currentUrl = window.location.pathname;

    if (l === currentUrl) {
      return;
    }

    if (typeof onClick === "function") {
      onClick();
    }
    if (!currentTarget.hasAttribute("data-navigate")) {
      return;
    }

    e.preventDefault();
    eventDispatcher.trigger("_navigate", { currentTarget, callback, l });
  };

  if (__html) {
    return (
      <Link
        to={to}
        activeClassName={activeClassName}
        className={className}
        dangerouslySetInnerHTML={{ __html }}
        title={title}
        data-navigate
        onClick={(e) => onClickLink(e, to)}
      ></Link>
    );
  } else if (href) {
    return (
      <a
        href={href}
        className={className}
        target="_blank"
        title={title}
        rel="noopener noreferrer"
        onClick={(e) => onClickLink(e, href)}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link
        to={to}
        title={title}
        activeClassName={activeClassName}
        className={className}
        data-navigate
        onClick={(e) => onClickLink(e, to)}
      >
        {children}
      </Link>
    );
  }
};
