import MiniSignal, { MiniSignalBinding } from "mini-signals";

const events: { [key: string]: MiniSignal } = {};
const eventExists = (eventName: string) => {
  return events[eventName] && events[eventName] instanceof MiniSignal;
};

const eventDispatcher = {
  getEvents: () => {
    return events;
  },
  getHandlers: (eventName: string, exists = false) => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].handlers(exists);
  },
  off: (eventName: string, callback: MiniSignalBinding) => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    events[eventName].detach(callback);
  },
  offAll: (eventName: string) => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].detachAll();
  },
  on: (eventName: string, callback: (params?: any) => void) => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].add(callback);
  },
  once: (eventName: string, callback: () => void) => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].once(callback);
  },
  set: (eventName: string) => {
    return (events[eventName] = new MiniSignal());
  },
  trigger: (eventName: string, params = {}) => {
    if (!eventExists(eventName)) {
      throw new Error("event doesn't exists");
    }
    return events[eventName].dispatch(params);
  },
};

export default eventDispatcher;
