import React, { ReactNode } from "react";
import { useAlert } from "react-alert";
import LinkedInTag from "react-linkedin-insight";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { login } from "../../utils/api";

export const USERSPACE_URI = process.env.GATSBY_USERSPACE_BASE_URL || "";
const LINKED_IN_REDIRECT = `${process.env.GATSBY_BASE_URL}/login-check`;
const CLIENT_ID = process.env.GATSBY_LINKEDIN_CLIENT_ID;
const SCOPE = "r_emailaddress r_liteprofile";

interface LinkedInCta {
  redirectTo?: string;
  renderElement?: ({ onClick }: { onClick: () => void }) => ReactNode;
  variant?: "login" | "register";
}

const LinkedInConnectCTA: React.FC<LinkedInCta> = (props: LinkedInCta) => {
  const { redirectTo = USERSPACE_URI, variant = "login", renderElement } = props;
  const alert = useAlert();

  const handleSuccess = async ({ code }: { code: string }) => {
    await login(code, LINKED_IN_REDIRECT);
    window.location.href = redirectTo;
  };

  const handleFailure = () => {
    alert.error("Une erreur est survenue");
  };

  const handleClick = () => {
    if ("login" === variant) {
      LinkedInTag.track("1782626");
    } else {
      LinkedInTag.track("1782610");
    }
  };

  const defaultRenderElement = ({ onClick }: { onClick: () => void }) => {
    return <span onClick={onClick}>s'inscrire</span>;
  };

  return (
    <LinkedIn
      scope={SCOPE}
      clientId={CLIENT_ID}
      onClick={handleClick}
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      redirectUri={LINKED_IN_REDIRECT}
      renderElement={renderElement || defaultRenderElement}
    />
  );
};

export default LinkedInConnectCTA;
